/* eslint-disable space-before-function-paren, quotes, semi, spaced-comment */
import "./scss/style.scss";
import Cookies from "./js/cookies";
import DisableScroll from "./js/lib/disable-scroll";

document.addEventListener("DOMContentLoaded", function () {
  // Disable scroll
  window.disableScroll = new DisableScroll();
});

window.addEventListener("load", function () {
  // Open cookies details popup
  window.cookiesDetailsOpen = function cookiesDetailsOpen() {
    const detailsExternalButtons = document.querySelectorAll(
      "a[href='#cookies-config']"
    );
    if (detailsExternalButtons.length > 0) {
      if (typeof cookiesPolicyLink !== "undefined") {
        Cookies();
      }
    }
  };

  // Cookies banner
  window.cookies = new Cookies();
});
